import React from 'react'
import styled from 'styled-components'
import GooglePlay from '../assets/google-play.png'
import AppStore from '../assets/app-store.png'
import {ContentContainer, ContentDescription, ContentHeader, ContentWrapper, InlineButtons, TopLine} from './styles/global-styles'
import {hardWayData, easyWaySteps, faqCreatorsData} from '../data/creators-data'
import AppFaq from './faq-section'
import {FaHandshake, FaHandshakeAltSlash} from 'react-icons/fa'

const GetAppButtons = () => (
  <InlineButtons>
    <a href="https://apps.apple.com/app/id1597098643" rel="noopener" ><DownloadAppButton src={AppStore} alt="Baixar App na App Store" /></a>
    <a href="https://play.google.com/store/apps/details?id=com.privetly" rel="noopener" ><DownloadAppButton src={GooglePlay} alt="Baixar App na Google Play" /></a>
  </InlineButtons>
)

const Creators = () => {
  return (
    <div id="creators-section">
      <ContentContainer background="#292A55">
        <ContentWrapper isLarge="false">
          <TopLine>
            Para Criadores
          </TopLine>
          <ContentHeader>
            Venda conteúdos exclusivos e privados com simplicidade.
          </ContentHeader>
          <ContentDescription>
            Crie pacotes, defina os preços e compartilhe com seguidores. Para fãs ou pedidos especiais, monte um pack exclusivos, gere links únicos e, se quiser, adicione um desconto. Tudo de forma prática e rápida, pra você e quem te segue.<br /><br />Controle tudo pelo app.
          </ContentDescription>
        </ContentWrapper>
        <GetAppButtons />
      </ContentContainer>
      <ContentContainer>
        <ContentWrapper isLarge="true">
          <ContentHeader color='#E8796E'>
            <span>Os jeitos difíceis e genéricos</span> de vender conteúdo
          </ContentHeader>
          <GridItems gap='1rem'>
            {hardWayData.map((column, indexColumn) => (
              <GridColumnAutoHeight key={indexColumn}>
                {column.map((item, index) => (
                  <HardWayCard key={index}>
                    <h3>{item.title}</h3>
                    <p>{item.description}</p>
                    <br />

                    <h4><FaHandshake css={`color: #3DD972; font-size: 1.2rem;`} /> Prós</h4>
                    <ul>{item.pros.map((pro, indexPros) => (
                      <li key={indexPros}>{pro}</li>
                    ))}</ul>
                    <br />

                    <h4><FaHandshakeAltSlash css={`color: #E8796E; font-size: 1.2rem;`} /> Contras</h4>
                    <ul>{item.cons.map((con, indexCons) => (
                      <li key={indexCons}>{con}</li>
                    ))}</ul>
                  </HardWayCard>
                ))}
              </GridColumnAutoHeight>
            ))}
          </GridItems>
          <ContentHeader color='#B393FF'>
            <span>O jeito fácil e intimista</span> de vender conteúdo
          </ContentHeader>
          <GridItems gap='5rem'>
            {easyWaySteps.map((step, index) => {
              if (index % 2 === 0) {
                return (
                  <GridColumn key={index}>
                    {step.img}
                    <EasyWayContent>
                      <h3>{step.title}</h3>
                      <p>{step.description}</p>
                    </EasyWayContent>
                  </GridColumn>
                )
              }
              return (
                <GridColumn key={index}>
                  <EasyWayContent>
                    <h3>{step.title}</h3>
                    <p>{step.description}</p>
                  </EasyWayContent>
                  {step.img}
                </GridColumn>
              )
            })}
          </GridItems>
        </ContentWrapper>
        <ContentWrapper isLarge="true" textAlign="start">
          <AppFaq dataToShow={faqCreatorsData} title='Perguntas Frequentes (Criadores)' />
        </ContentWrapper>
        <ContentWrapper isLarge="true" id="download-app-section">
          <JoinCreatorsSection>
            <ContentHeader>Junte-se agora aos criadores que estão monetizando e conectando com suas audiências em um outro patamar</ContentHeader>
            <GetAppButtons />
          </JoinCreatorsSection>
        </ContentWrapper>
      </ContentContainer>
    </div>
  )
}

export default Creators

const DownloadAppButton = styled.img`
  object-fit: contain;

  @media screen and (min-width: 768px) {
    height: 70px;
    margin: 0 0.5rem 0 0.5rem;
  }
  @media screen and (max-width: 768px) {
    width: 180px;
    margin-bottom: 0.5rem;
  }
`
const GridColumnAutoHeight = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 1fr;
  grid-gap: 1rem;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

const GridColumn = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
  grid-gap: 1rem;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`
// const ColumnTwo = styled.div`
//   display: grid;
//   grid-template-columns: 1fr 1fr;
//   margin-top: 2rem;
//   grid-gap: 10px;

//   @media screen and (max-width: 500px) {
//     grid-template-columns: 1fr;
//   }
// `

const HardWayCard = styled.div`
  padding: 2rem;
  background: #141414;
  color: #fff;
  border: 1px #2E2E2E solid;
  border-radius: 12px;
  text-align: start;
  transition: 0.7s !important;

  h3 {
    margin-bottom: 0.5rem;
  }

  h4 {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    grid-gap: 0.75rem;
  }

  p {
    color: #C0C0C0;
  }

  ul {
    padding-left: 2rem;
    margin-top: 0.5rem;
    color: #C0C0C0;
  }

  &:hover {
    background: #232323;
    transform: translateY(-4px);
  }
`

const GridItems = styled.div`
  padding: 0 1.5rem 0 1.5rem;
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-gap: ${({gap}) => (gap || '0px')};
  justify-content: center;
  margin-bottom: 5rem;
`

const EasyWayContent = styled.div`
  text-align: left;
  justify-content: start;

  h3 {
    margin-bottom: 1rem;
    font-size: clamp(1.3rem, 4vw, 1.5rem);
    font-weight: 500;
    
  }

  p {
    color: #C0C0C0;
  }
`
const JoinCreatorsSection = styled.div`
  padding: 2rem;
  background: #8B5CF5;
  border-radius: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;
  margin-left: 2rem;
  margin-right: 2rem;
`